import http from '../utils/request.js'


const rank = {
    // 返回所有achievements
    getRankInfoAnalysis(start, end, id) {
        if (!id) id = 1
        const params = {
            workshop_id: id,
            start_date: start,
            end_date: end
        }
        return http.post(`/screen/workerAttendance`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },

}

export default rank;
